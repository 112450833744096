import React from 'react';
import { Image, Text } from '@belong/ui';
import clsx from 'clsx';

export const TrustpilotStars = ({ theme = 'light' }: { theme?: 'light' | 'dark' }) => {
  return (
    <div className="flex items-center">
      <div className="relative w-[127px] mr-xs">
        <Image src="/icons/trustpilot-stars.svg" alt="4.7 Stars" />
        <div className="absolute w-[8px] opacity-60 top-0 right-0 bottom-0" />
      </div>
      <Text
        variant="h3"
        fontWeight="semibold"
        className={clsx({ 'text-navy': theme === 'light', 'text-white': theme === 'dark' })}
      >
        on
      </Text>
      <div className="w-[120px] ml-xs mt-[3px]">
        <Image src="/logos/trustpilot-logo.svg" alt="Trustpilot" />
      </div>
    </div>
  );
};
